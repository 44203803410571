import api from '../api/api';

export default class EscalationsService {
    static async getRecords( startDate, endDate, size, page) {
        const params = {
            ...(startDate && { startDate }),
            ...(endDate && { endDate }),
            ...(size && { size }),
            ...(page && { page })
        }

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
            params: params,
        }
        return api.get('/escalation', config)
    }

    static async postComment (payload) {
        return api.post('/escalation', payload)
    }
}