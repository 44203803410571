import React, {Component, useEffect, useRef, useState} from 'react';
import styles from './Escalations.module.css';
import statStyles from '../StatPage/StatPage.module.css';
import {processTimeRange} from "../../utils/utils";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import EscalationsService from "../../services/EscalationsService";
import {useDispatch} from "react-redux";
import {setModalError} from "../../store/reducers/modalReducer";
import ReactLoading from "react-loading";
function Escalations() {
    const [timeRange, setTimeRange] = useState(["Last 24h", "Today", "Yesterday", "This week", "Last week", "This month", "Last month", "This year"]);
    const [selectedTimeRange, setSelectedTimeRange] = useState("Last 24h");
    const [statsStart, setStatsStart] = useState(new Date(Date.now() - 24 * 60 * 60 * 1000));
    const [statsEnd, setStatsEnd] = useState(new Date(Date.now()));
    const [paginationSize, setPaginationSize] = useState(10)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [escalationRecords, setEscalationRecords] = useState([]);
    const [causeRecord,setCauseRecord] = useState("");
    const [isCauseWindowActive, setIsCauseWindowActive] = useState("");
    const [refreshData, setRefreshData] = useState(false);
    const dispatch = useDispatch();

    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    }
    useEffect(() => {
        processTimeRange(selectedTimeRange, setStatsStart, setStatsEnd)
    }, [selectedTimeRange]);

    useEffect(() => {
        const escalations = async () =>{
            try {
                setEscalationRecords([])
                setIsLoading(true);
                const response = await EscalationsService.getRecords(dayjs(statsStart).unix(), dayjs(statsEnd).unix(), paginationSize, currentPage);
                if (response.status === 200) {
                    setIsLoading(false);
                    if (response.data.escalations.length > 0) {
                        setEscalationRecords(response.data.escalations)
                        setTotalRecords(response.data.total)
                    }
                }
            }
            catch(error){
                dispatch(setModalError("Oops... Something went wrong!"));
                setIsLoading(false);
            }
        }
        escalations();
    }, [statsStart, statsEnd, paginationSize, currentPage,refreshData]);

    const sendEscalationRecord = async (message) => {
        setIsLoading(true);
        const payload = {
           comment:  message,
            record_id: isCauseWindowActive,
        }
        try {
            const commentResponse = await EscalationsService.postComment(payload)
            if (commentResponse.status === 200) {
                setEscalationRecords((prevRecords) =>
                    prevRecords.map((record) =>
                        record._id === isCauseWindowActive
                            ? { ...record, causes: message } // Обновляем комментарий
                            : record
                    )
                );
                setRefreshData((prev) => !prev);

            }
        } catch (e) {
            dispatch(setModalError("Oops... Something went wrong!"));
        } finally {
            setIsLoading(false);
        }
    }

    const onPageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    }

    const onCauseOkClick = (message) => {
        sendEscalationRecord(message)
        setIsCauseWindowActive("");
    }

    const onCauseCancelClick = () => {
        setCauseRecord("")
        setIsCauseWindowActive("");
    }

    const handleKeyPress = (e) => {
       if (e.key === "Enter") {
           if (e.ctrlKey || e.shiftKey || e.metaKey) {
           } else {
               e.preventDefault();
               onCauseOkClick(causeRecord)
               document.activeElement.blur();
           }
       }
       if (e.key === "Escape") {
           e.preventDefault();
           onCauseCancelClick();
           document.activeElement.blur();
       }
    }

        return (
            <>
             <div className={styles.escalationPage}>
                 <div className={statStyles.controlBar}>
                     <div className={`${statStyles.buttonBlock} ${statStyles.timeRangeBlock}`}>
                         <button className={statStyles.button}>{selectedTimeRange}</button>
                         <ul className={`${statStyles.dropdownBlock} ${statStyles.timeRangeDropdownBlock}`}>
                             {
                                 timeRange.map(range => (
                                     <li className={statStyles.dropdownItem}
                                         onClick={() => setSelectedTimeRange(range)}>{range}</li>
                                 ))
                             }
                         </ul>
                     </div>
                     <div className={`${statStyles.buttonBlock} ${statStyles.dateForm}`}>
                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                             <MobileDateTimePicker value={dayjs(statsStart)} onChange={(e) => {
                                 const changedDate = formatDate(e);
                                 setStatsStart(new Date(changedDate))
                             }}
                                                   ampm={false}
                                                   inputFormat="YYYY-MM-DD HH:mm"
                             />
                         </LocalizationProvider>
                     </div>
                     <div className={`${statStyles.buttonBlock} ${statStyles.dateForm}`}>
                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                             <MobileDateTimePicker value={dayjs(statsEnd)} onChange={(e) => {
                                 const changedDate = formatDate(e);
                                 setStatsEnd(new Date(changedDate))
                             }}
                                                   ampm={false}
                                                   inputFormat="YYYY-MM-DD HH:mm"
                             />
                         </LocalizationProvider>
                     </div>
                 </div>
                <div
                    className={styles.escalationTable}>
                    <div className={styles.tableCell}>
                        Logged
                    </div>
                    <div className={styles.tableCell}>
                        Service
                    </div>
                    <div className={styles.tableCell}>
                        History
                    </div>
                    <div className={styles.tableCell}>
                        Investigation
                    </div>
                    <div className={styles.tableCell}>
                        Author
                    </div>
                    {
                        escalationRecords.length > 0
                            ? escalationRecords
                                .slice()
                                .sort((a, b) => b.logged - a.logged)
                                .map((record, index) => (
                                <React.Fragment key={record._id}>
                                    <td className={styles.tableCell}>
                                        {dayjs.unix(record.logged).format('DD.MM.YYYY HH:mm')}
                                    </td>
                                    <td className={styles.tableCell}>
                                        {record.service}
                                    </td>
                                    <td className={styles.tableCell}>
                                        {record.history}
                                    </td>
                                    <td className={`${styles.tableCell} ${styles.inputTableCell}`}>
                                        <textarea className={`${styles.inputTextArea} ${isCauseWindowActive === record._id ? styles.inputTextAreaActive : null}`}
                                        value={isCauseWindowActive === record._id ? causeRecord : (record.causes || 'none')}
                                        onChange={(e) => {
                                            setCauseRecord(e.currentTarget.value)
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setIsCauseWindowActive(record._id);
                                            setCauseRecord(record.causes ? record.causes : 'none');
                                        }}
                                        onKeyDown={handleKeyPress}
                                        />
                                        <div className={`${styles.inputCellButtonBlock} ${isCauseWindowActive === record._id ? styles.inputCellButtonBlockActive : null}`}>
                                            <button className={`${styles.inputCellButton} ${styles.inputOkButton}`}
                                            disabled={!isCauseWindowActive}
                                            onClick={(e) => {onCauseOkClick(causeRecord)}}
                                            >
                                                OK
                                            </button>
                                            <button className={`${styles.inputCellButton} ${styles.inputCancelButton}`}
                                            disabled={!isCauseWindowActive}
                                            onClick={(e) => {onCauseCancelClick()}}
                                            >
                                                CANCEL
                                            </button>
                                        </div>
                                    </td>
                                    <td className={styles.tableCell}>
                                        {record.author ? <p>{record.author}</p> : null}
                                        {record.time ? <p>{dayjs.unix(record.time).format('DD.MM.YYYY HH:mm')}</p> : null}
                                    </td>
                                </React.Fragment>
                                ))
                            : null

                    }
                </div>
                 <div className={styles.paginationBock}>
                     <div className={styles.paginationSizeBlock}>
                         <div className={styles.paginationSize}>
                             {paginationSize}
                         </div>
                         <ul className={styles.paginationDropDown}>
                             <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(10)}>10</li>
                             <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(20)}>20</li>
                             <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(30)}>30</li>
                            <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(40)}>40</li>
                            <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(50)}>50</li>
                        </ul>
                    </div>
                    <p className={styles.paginationSizeText}>Record per page</p>
                    <p className={styles.totalRecordsText}> Showing from {(currentPage * paginationSize) - paginationSize + 1} to {
                        (currentPage * paginationSize) < totalRecords
                        ? (currentPage * paginationSize)
                        : totalRecords
                    } from {totalRecords} records</p>
                    <ul className={styles.paginationPagesBlock}>
                        <li className={`${styles.paginationPageButtonCover} ${styles.paginationPageButtonFirst}`}>
                            <button className={styles.paginationPageButton}
                                    onClick={() => onPageClick(currentPage - 1)}
                                    disabled={currentPage < 2}
                            />
                        </li>
                        {
                            currentPage > 3 ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton}
                                            onClick={() => onPageClick(1)}>
                                        1
                                    </button>
                                     </li>
                                :
                                null
                        }
                        {
                            currentPage > 4 ?
                                <li className={styles.paginationPageButtonCover}> ... </li>
                                :
                                null
                        }
                        {
                            currentPage > 2 ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button
                                        className={styles.paginationPageButton}
                                        onClick={() => onPageClick(currentPage - 2)}
                                       >
                                        {currentPage - 2}
                                    </button>
                                </li>
                                :
                                null
                        }
                        {
                            currentPage > 1 ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button
                                        className={styles.paginationPageButton}
                                        onClick={() => onPageClick(currentPage - 1)}>
                                        {currentPage - 1}
                                    </button>
                                </li>
                                :
                                null
                        }
                        <li className={`${styles.paginationPageButtonCover}  ${styles.paginationPageButtonCoverActive}`}>
                            <button className={`${styles.paginationPageButton} ${styles.paginationPageButtonActive}`}>
                                {currentPage}
                            </button>
                        </li>
                        {
                            (Math.ceil(totalRecords / paginationSize) ) >= (currentPage + 1) ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton} onClick={() => onPageClick(currentPage + 1)}>
                                        {currentPage + 1}
                                    </button>
                                </li>
                                :
                                null
                        }
                        {
                            (Math.ceil(totalRecords / paginationSize) ) >= (currentPage + 2) ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton} onClick={() => onPageClick(currentPage + 2)}>
                                        {currentPage + 2}
                                    </button>
                                </li>
                                :
                                null
                        }
                        {
                            (Math.ceil(totalRecords / paginationSize) ) >= (currentPage + 4) ?
                                <li className={styles.paginationPageButtonCover}> ... </li>
                                :
                                null
                        }
                        {
                            (Math.ceil(totalRecords / paginationSize) ) >= (currentPage + 3) ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton}
                                            onClick={() => onPageClick(Math.ceil(totalRecords / paginationSize))}>
                                        {  Math.ceil(totalRecords / paginationSize)}
                                    </button>
                                </li>
                                :
                                null
                        }
                        <li className={`${styles.paginationPageButtonCover} ${styles.paginationPageButtonLast}`}>
                            <button className={styles.paginationPageButton}
                                    onClick={() => onPageClick(currentPage + 1)}
                                    disabled={currentPage >= Math.ceil(totalRecords / paginationSize)}
                            />
                        </li>
                    </ul>
                </div>
             </div>
                {
                    isLoading
                        ?
                        <div className={styles.loadingOverlay}>
                            <ReactLoading color={'#01A2D8'} type={"spin"} height={200} width={100}/>
                        </div>
                        :
                        <></>
                }
            </>
        );
}

export default Escalations;